body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.newsreader-bold600 {
  font-family: "Newsreader", serif;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
  color: #1a1a1a;
}

.newsreader-bold600_fadeEffect {
  font-family: "Newsreader", serif;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
  color: #1a1a1a;
  animation: fadeEffect 1s alternate;
  animation-iteration-count: 5;
}

@keyframes fadeEffect {
  0% {
    opacity: 0; /* Start with opacity 0 */
  }
  50% {
    opacity: 1; /* Show dot */
  }
  100% {
    opacity: 0; /* End with opacity 0 */
  }
}
::-webkit-scrollbar {
  height: 5px;
  width: 5px;
  background: var(--background);
}

::-webkit-scrollbar-thumb {
  background: var(--accent);
  -webkit-border-radius: 1ex;
}

::-webkit-scrollbar-corner {
  background: var(--background);
}
.progress-bar {
  z-index: 100000;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 5px;
  background: rgb(177, 177, 177);
  transform-origin: 0%;
}
